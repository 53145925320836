export default {
  mesh: 'Mesh',
  'login-to': 'Se connecter à ',
  notifications: 'Notifications',
  'help-center': "Centre d'aide",
  slogan: `L'intelligence artificielle au service de ta gestion d'activité`,
  'select-site': 'Sélectionne un site pour voir les informations',
  'search-site': 'Quel site ?',
  'forbidden-view': {
    'request-access': 'Demander l’accès',
    'request-sent': "Ta demande d'habilitation a été envoyée.",
    'change-account': 'Changer de compte',
    title: 'Tu n’es pas autorisé à accéder à cette application',
    desc: 'Besoin de demander l’accès?<br>Décris ci-dessous pourquoi tu souhaiterais avoir accès à l’application.',
    'textarea-placehoder': 'Entre ta description (500 caractères max)'
  },
  'modal-refresh': {
    'new-version': 'Nouvelle version',
    'new-version-available': 'Une nouvelle version est disponible.',
    'reload-page': 'Voulez-vous recharger la page maintenant ?',
    close: 'Fermer',
    reload: 'Recharger'
  },
  'home-view': {
    hello: 'Bonjour',
    'today-is': 'Nous sommes le',
    'time-is': 'Il est',
    'todays-nameday': 'Aujourd’hui nous fêtons les',
    'tomorrow-day': 'Ma journée de demain',
    'data-availability': {
      title: "L'état et l'arrivée de la donnée dans Smart Activity",
      hour: `L'annonce colis en PGC sec et non alimentaire est disponible <b>à partir de 7h30.</b> <br>Vous pourrez avoir des données avant mais celles-ci seront  <b>fausses ou incomplètes</b>.<br>Pour le frais LS et le MBA LS, l'annonce est disponible <b>à partir de 8h15.</b>`,
      bruchrate: `L'annonce colis en frais LS et MBA LS correspond aux commandes envoyées et donc ne tient <b>pas compte du taux de ruptures</b> chez nos fournisseurs.`,
      question: `Il s’agit d'un prévisionnel colis, il peut donc y avoir quelques écarts suivants les aléas en logistique (reste à quai par ex). En cas de doute, il vous faut contacter votre Responsable Efficacité Clients : <b>Seul le REC</b> peut appeler le support clients.`
    }
  },
  'shelving-duration-view': {
    'shelving-duration': 'Temps de mise en rayon',
    'view-description': 'Ici, tu peux <span class="highlight">diminuer</span> le temps de mise en rayon moyen des catégories.'
  },
  'shelving-splitting-view': {
    title: 'Paramétrage de la repasse',
    'view-description': 'Ici, tu peux <span class="highlight">répartir sur plusieurs jours</span> le remplissage des colis reçus',
    warning: '⚠️ Attention!',
    'red-alert': 'Alerte rouge!',
    'bad-splitting': "Tu n'as pas réparti la totalité du remplissage",
    'clocked-settings': 'Param. cadencé',
    clocked: 'Cadencé'
  },
  'tasks-placement-view': {
    'title': "Positionnement des charges d'arrivages",
    'view-description': 'Ici, tu peux <span class="highlight">positionner les charges d\'arrivages</span> avant ou après l\'ouverture du magasin.',
    'area': 'Zones',
    'default-placement': 'Placement par défaut',
    'before-opening': 'Avant ouverture',
    'after-opening': 'Après ouverture'
  },
  'announcement-view': {
    print: 'Imprimer',
    'unfold-all': 'Tout déplier',
    'fold-all': 'Tout replier',
    'delivery-of': 'Remplissage du',
    permanent: 'Fond de rayon',
    dr: 'Double réappro',
    quantity: 'Quantité',
    promo: 'Promo',
    homogeneous: 'Palettes (données partielles et/ou fausses)',
    'flow-type': {
      stock: 'Stock',
      transit: 'Transit',
      direct: 'Direct'
    },
    total: 'Total',
    'shelving-load': 'Charge <br> MeR',
    'total-load': 'Charge <br> totale',
    shelving: 'MeR',
    load: 'Charge',
    'area-announcement-title': 'Colis du lendemain par zones',
    'area-view-description':
      'Ici, tu peux consulter ton <span class="highlight">annonce colis</span> regroupée par <span class="highlight">zones</span>',
    'nomenclature-announcement-title': 'Colis du lendemain par nomenclature',
    'nomenclature-view-description':
      'Ici, tu peux consulter ton <span class="highlight">annonce colis</span> regroupée par <span class="highlight">nomenclature</span>'
  },
  'schedule-view': {
    'activities-schedule': "Planning de la gestion d'activité",
    'view-description': `Ici, tu peux consulter le <span class="highlight">planning intelligent</span> et le modifier/configurer à ta guise.`,
    'configure-my-schedule': 'Configurer',
    'schedule-settings': 'Configuration du planning',
    'no-collab': "N'aurais-tu pas oublié de définir ton équipe dans smartcollab ?",
    'go-to-smartcollab': 'Accède à smartcollab via le bouton NOS APPS en haut à droite',
    'fold-all-areas': 'Replier les zones',
    'unfold-all-areas': 'Déplier les zones',
    'go-to-preview': 'Accèder à la prévisualisation',
    'back-planning-management': 'Retour à la gestion du planning',
    'config-tabs': {
      startAndEndTime: {
        title: 'Heures de début & fin d’activité',
        'set-start-time': 'Définir une heure de début d’activité',
        'set-end-time': 'Définir une heure de fin d’activité',
        'exceptional-time': {
          title: 'Définir une heure de début exceptionnelle',
          'selected-day': 'Jour sélectionné :',
          'concerned-areas': 'Zones concernées',
          'start-time': "Heure de début d'activité",
          ok: 'Enregistrer cette heure de début exceptionnelle',
          delete: 'Supprimer cette heure de début exceptionnelle',
          'defined-or-not':
            'Aucune heure exceptionnelle définie pour ce jour. | Une heure exceptionnelle est définie pour ce jour. | {count} heures exceptionnelles sont définies pour ce jour.',
          'select-area': 'Selectionner une zone | 1 zone sélectionnée | {count} zones sélectionnées'
        }
      },
      fixedTasks: {
        title: 'Affectation charges fixes',
        multicolors: 'Mode multi-couleurs',
        'all-collabs': 'Tous les collaborateurs',
        'without-affectation': 'Sans affectation',
        'add-fixed-task': "Ajout d'une charge fixe",
        'add-fast-fixed-task': "Ajout rapide d'une charge fixe",
        'fast-fixed-task-info': {
          title: 'C\'est quoi un "ajout rapide" ?',
          content:
            'Cet ajout se fait sans avoir besoin de regénérer le planning. La charge se placera à la fin du planning du ou des collaborateur(s) sélectionné(s). La charge sera forcément ponctuelle, et l’heure de début ne peut pas être définie.'
        },
        'fixed-task': 'Charge fixe | Charge fixe  | Charges fixes',
        'variable-tasks': 'Charges variables',
        cancel: 'Annuler',
        next: 'Suivant',
        'apply-changes': 'Appliquer les modifications',
        'delete-task': 'Supprimer la charge',
        'step-1': {
          'what-task': 'Quelle charge fixe je souhaite affecter ?',
          'defined-task': 'Charge fixe définie',
          'custom-task': 'Charge fixe personnalisée',
          'select-defined-task': 'Je sélectionne une charge définie',
          'name-custom-task': 'Je nomme la charge fixe',
          'name-custom-task-placeholder': 'Nommer la charge fixe'
        },
        'step-2': {
          'who-concerned': 'Qui est concerné par cette charge fixe ?',
          'all-collabs': 'Tous les collaborateurs',
          'some-collabs': 'Un ou plusieurs collaborateurs',
          collabs: 'Collaborateurs'
        },
        'step-3': {
          'what-frequency': 'A quelle fréquence a lieu la charge',
          punctual: 'Ponctuelle',
          recurrent: 'Récurrente',
          'when-start': 'À quel moment se déroule la charge ?',
          'specific-time': 'À une heure définie',
          following: 'Après une charge existante',
          'how-long': 'Combien de temps dure la charge ?',
          'select-date': 'Je sélectionne une date',
          'select-frequency': 'Je sélectionne une fréquence',
          'all-the': 'Toutes les',
          weeks: 'Semaines',
          'all-the-weeks': 'Jamais | Ttes les semaines | Ttes les {count} semaines',
          'concerned-days': 'Jours concernés',
          after: 'Après',
          'select-task': 'Selectionner une tache',
          days: {
            all: 'Tous les jours',
            monday: 'Lun.',
            tuesday: 'Mar.',
            wednesday: 'Mer.',
            thursday: 'Jeu.',
            friday: 'Ven.',
            saturday: 'Sam.',
            sunday: 'Dim.'
          }
        },
        'step-4': {
          'task-period': 'Sur quelle période s’étend cette charge ?',
          from: 'A partir du ',
          to: "Jusqu'au",
          optional: 'Facultatif',
          'add-to-date': 'Ajouter une date de fin'
        }
      },
      collabs: {
        title: 'Gestion des collabs',
        'select-collab': 'Sélectionne un collaborateur pour effectuer tes actions !',
        actions: {
          absence: 'Saisir des absences',
          attendance: 'Jours de présences et horaires',
          exceptionnalHours: 'Horaires d’activité exceptionnelle'
        },
        absences: {
          title: 'Sélectionne une ou des journée(s) d’absence :'
        },
        attendance: {
          title: 'Sélectionne les jours de présence :',
          'sub-title': 'Heure de début d’activité du collaborateur',
          'custom-hour': 'Heure personnalisée',
          'site-hour': 'Heure du magasin'
        },
        exceptionnalHours: {
          title: 'Sélectionne le ou les jours d’horaires exceptionnels :',
          'sub-title': 'Heure de début d’activité pour le'
        },
        'calendar-legend': {
          absence: 'Absences',
          exceptionnal: 'Horaires exceptionnels',
          attendance: 'Jours de présence',
          rest: 'Jours non travaillés'
        }
      }
    },
    schedule: {
      'permanent-parcel-quantity': 'Nbre colis hors promo',
      'shelving-duration': 'Temps de charge total',
      'transfer-all-tasks': "Transférer l'ensemble des charges",
      'generation-time': 'Planning généré le ',
      'draggable-task': {
        cut: "Couper l'activité",
        'add-minutes': 'Ajouter 5 minutes',
        'remove-minutes': 'Retirer 5 minutes',
        'divide-time': 'Répartis ton temps',
        'cut-task': 'Découper la charge',
        'delete-task': 'Supprimer la charge',
        'delete-task-everybody': 'Supprimer la charge pour tout le monde',
        'transfer-task': 'Transférer la charge',
        'transfer-task-modal': {
          title: 'Transférer la charge',
          'to-who': 'A qui voulez-vous transférer cette charge ?',
          'defined-collab': 'Un collaborateur défini dans SmartCollab',
          'temp-collab': 'Un collaborateur temporaire',
          'variable-tasks': 'Charges variables',
          'all-tasks': 'Toutes les charges',
          'task-name': 'Nom de la charge :',
          duration: 'Durée :',
          'task-type': 'Type de charge :',
          'search-by-name-uid': 'Rechercher par nom ou matricule',
          'search-collab': 'Rechercher le collaborateur',
          validate: 'Valider',
          'no-match': 'Aucun collaborateur correspondant.',
          'new-temp-collab': 'Transférer à un nouveau collaborateur temporaire',
          'type-temp-collab-name': 'Saisir le nom du collaborateur temporaire',
          'existing-collab': 'Transférer à un collaborateur existant',
          'error-defined-collab': 'Pour transférer la charge, il faut sélectionner un collaborateur dans la liste.',
          'error-temp-collab':
            "Pour transférer la charge, il faut sélectionner un collaborateur temporaire dans la liste ou saisir le nom d'un nouveau collaborateur temporaire."
        },
        'transfer-all-tasks-modal': {
          title: "Transférer l'ensemble des charges d'un collaborateur",
          'select-task-type': 'Quels type de charge voulez-vous transférer ?',
          'to-who': 'A qui voulez-vous transférer ces charges ?',
          'error-missing-name': "Pour transférer la charge, il faut saisir le nom d'un nouveau collaborateur temporaire.",
          'error-temp-collab-ever-exists': "Ce collaborateur existe déjà. Merci de saisir le nom d'un nouveau collaborateur temporaire"
        }
      },
      'absent-collab': {
        title: 'Ce collaborateur est absent.',
        content: `N'oublies pas de bien réaffecter ses charges !`
      },
      'add-temp-collab': 'Ajouter un collab temporaire',
      'temp-collab-already-exists': 'Il existe déjà un collaborateur temporaire avec le même nom.',
      'name-here': 'Nommer ici',
      'regeneration-alert': {
        title: 'Regénération du planning',
        warning: 'Attention !',
        description: `L'intelligence artificielle de smartactivity va régénérer totalement le planning du site.
        Toutes les modifications apportées manuellement par toi ou d'autres collaborateurs sur cette zone seront perdues à vie.`,
        'description-area': `L'intelligence artificielle de smartactivity va régénérer totalement le planning de la zone.
        Toutes les modifications apportées manuellement par toi ou d'autres collaborateurs sur cette zone seront perdues à vie.`,
        confirmation: 'Es-tu sûr(e) de vouloir regénérer le planning du site ?',
        'confirmation-area': 'Es-tu sûr(e) de vouloir regénérer le planning de la zone ?',
        no: 'Non',
        yes: 'Oui, regénérer le planning'
      },
      'delete-collab-alert': {
        title: "Suppression d'un collaborateur temporaire",
        warning: 'Attention !',
        'description-variable': `En supprimant ce collaborateur, l'ensemble des charges fixes qui lui sont affectés vont être supprimées.
        Pense à transférer les charges qui te seront nécessaire avant de confirmer la suppression.`,
        'description-fixed':
          "Tu ne peux pas supprimer un collaborateur qui dispose de charges d'arrivage. Déplace ses charges d'arrivage avant de supprimer un collaborateur temporaire.",
        confirmation: 'Es-tu sûr(e) de vouloir supprimer ce collaborateur temporaire ?',
        cancel: 'Annuler',
        yes: "Oui, supprimer le collab'"
      },
      'comment-modal': {
        title: 'Commentaire',
        placeholder: 'Ajoute ton commentaire ici'
      },
      'print-modal': {
        title: 'Que souhaites-tu imprimer ?',
        'which-view': {
          title: 'Que souhaites-tu imprimer ?',
          coordo: 'Vue coordo',
          collabs: 'Vue collaborateurs'
        },
        'time-slot': {
          title: 'Quelle plage horaires faire apparaître ?',
          from: 'De',
          to: 'à',
          advice: 'Conseil : ',
          'advice-content': 'Ne choisis pas une plage horaire trop longue ! La bonne lecture du planning sera impactée.'
        },
        'compact-mode': {
          title: 'Souhaites-tu un affichage détaillé ?',
          compact: 'Affichage compact',
          comment: 'Affichage des commentaires'
        },
        'absent-mode': {
          title: 'Souhaites-tu afficher les absents ?',
          absent: 'Affichage des absents'
        },
        'which-areas': 'Quelle(s) zone(s) faire apparaître ?',
        'all-areas': 'Toutes les zones',
        'not-affected': 'Non affectés',
        cancel: 'Annuler',
        print: 'Imprimer'
      }
    },
    'punctual-task-modal': {
      'new-punctual-task': 'Nouvelle charge ponctuelle',
      'task-name': 'Intitulé de la charge',
      'enter-name': 'Entrez un nom de charge...',
      'cancel': 'Annuler',
      'add-task': 'Ajouter la charge'
    },
    'variable-tasks': {
      ARRIVAGE: 'Arrivage',
      REPASSE: 'Repasse',
      NON_ALIMENTARY_ARRIVAGE: 'Arrive non alimentaire'
    },
    print: {
      fixedTask: 'Charge fixe',
      repasse: 'Repasse',
      arrivage: 'Arrivage',
      otherArea: 'Autre zone',
      the: 'Le',
      absent: 'ABSENT'
    },
    'fixed-task': 'Charge fixe',
    'late-arrivals': {
      'partial-data': 'Données incomplètes',
      explication:
        'Nous avons reçu de la nouvelle donnée annonce colis depuis la dernière génération du planning, veux-tu les ajouter automatiquement ? Les charges ajoutées seront placées à la suite des charges des collaborateurs concernés.',
      'concerned-areas': 'Zones concernées',
      'manage-manually': 'Gérer manuellement',
      'add-automatically': 'Ajouter automatiquement',
      'add-announcement': 'Ajouter les colis',
      indicators: 'Décalage de {time} ({packages}) colis sur "{areaLabel}"',
      'missing-packages': 'Colis manquants',
      packages: 'colis'
    },
  },
  'custom-repasses-popin': {
    'user-explication':
      "Tu peux configurer tes repasses en fonction des jours de réception des marchandises. Attention : Le dimanche n'est pas inclu dans ce paramétrage. Dans le cas d'une livraison le vendredi, le J+2 correspondra alors au lundi suivant. Attention également aux jours fériés, ils ne seront pas comptés non plus.",
    'arrival-day': "Jour d'arrivage",
    cancel: 'Annuler',
    confirm: 'Valider les repasses',
    'disable-timing': 'Désactiver le cadencement'
  }
}
